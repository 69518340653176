import { inject, Injectable } from '@angular/core';
import { CompanyService, ICompanyReturn } from '../company/company.service';
import { HttpClient } from '@angular/common/http';
import { LoggingService } from '../utils/logging.service';
import { map, tap } from 'rxjs';

const SERVER_PATH = import.meta.env.NG_APP_API_URL;

export interface IContactStatus {
  label: string;
  value: number;
}

export interface IContactList {
  count: number;
  data: [];
}

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private readonly http = inject(HttpClient);
  private readonly companyService = inject(CompanyService);
  private readonly loggingService = inject(LoggingService);

  private readonly statusList: IContactStatus[] = [
    {
      label: 'general_status',
      value: 0
    },
    {
      label: 'follow_up_status',
      value: 1
    },
    {
      label: 'resolve_status',
      value: 2
    },
    {
      label: 'spam_status',
      value: 3
    }
  ];

  constructor() {
    console.warn('@@@ Contact Service @@@');
  }

  getContactList(limitPage?: any) {
    const queryLimit = limitPage ? `?page=${limitPage}&limit=100` : '';
    return this.http
      .get(
        `${SERVER_PATH}/customer/list/${
          this.companyService.getCompanyState()?.id
        }${queryLimit}`
      )
      .pipe(
        tap((res: any) =>
          this.loggingService.log('|||| [GET] :: Contact List All', res)
        ),
        map((res: any) => res)
      );
  }

  getContactByCondition(conditions: any, limitPage?: any) {
    console.warn('Func | getContactByCondition : conditions', conditions);
    const queryLimit = limitPage ? `&page=${limitPage}&limit=100` : '';

    const keyObj = Object.keys(conditions);
    const filterKey = keyObj
      .map((res, index) => {
        if (index === 0) {
          if (typeof conditions[res] === 'string') {
            // Type String
            return `?${res}=${conditions[res]}`;
          } else {
            // Type Array
            return `?${res}=${conditions[res].toString()}`;
          }
        } else {
          if (typeof conditions[res] === 'string') {
            // Type String
            return `&${res}=${conditions[res]}`;
          } else {
            // Type Array
            return `&${res}=${conditions[res].toString()}`;
          }
        }
      })
      .join('');
    console.log('filterKey', filterKey);
    return this.http
      .get(
        `${SERVER_PATH}/customer/list/${
          this.companyService.getCompanyState()?.id
        }${filterKey}${queryLimit}`
      )
      .pipe(
        tap((res: any) =>
          this.loggingService.log(
            '|||| [GET] :: Contact List By conditions',
            res
          )
        ),
        map((res: any) => res)
      );
  }

  getTagsListAll() {
    const { id } = this.companyService.getCompanyState() as ICompanyReturn;
    return this.http
      .get(`${SERVER_PATH}/tag/list/${id}`)
      .pipe(
        tap((res: any) =>
          this.loggingService.log('|||| [GET] :: Tag List All', res)
        )
      );
  }

  getStatusList(): IContactStatus[] {
    return this.statusList;
  }

  patchContact(data: any, contactId: string) {
    const { id } = this.companyService.getCompanyState() as ICompanyReturn;
    const dataSend = {
      ...data,
      compId: id
    };
    return this.http
      .patch(`${SERVER_PATH}/customer/${contactId}`, dataSend)
      .pipe(
        tap((res: any) =>
          this.loggingService.log('|||| [Patch] :: Update Contact', res)
        )
      );
  }

  manageLayout(i: number) {
    let layout: string;

    if ((i + 1) % 3 !== 0) {
      if (i % 2 === 0) {
        layout = 'pr-0 sm:pr-3';
      } else {
        layout = 'md:pr-3';
      }
    } else {
      if (i % 2 === 0) {
        layout = 'pr-0 sm:pr-3 md:pr-0';
      } else {
        layout = '';
      }
    }

    return layout;
  }
}
